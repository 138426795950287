interface DescriptionProps {
  description: string
}

export function Description({ description }: DescriptionProps) {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `${description} Equipamentos esportivos de alta qualidade para atender às necessidades do seu negócio.`,
      }}
      className="desktop-body-regular-text3 text-restructure-tertiary"
    />
  )
}
