import './styles.scss'
import { useEffect, useState } from 'react'
import axios from 'axios'

import ProductSlider from '../../product/ProductSlider'
import type { SearchResult } from '../../sections/ProductGalleryV2/types'
import TrainPartnes from './TrainPartners'
import DicasTreino from './DicasTreino'
import CompleteTrains from './CompleteTrains'
import TrainInHouse from './TrainInHouse'
import * as storeConfig from '../../../../store.config'
import { B2B } from '../../../store-config/B2B-Config'
import { B2C } from '../../../store-config/B2C-Config'
import { checkEnviromentIsB2B } from '../../../utils/checkEnviroment'

const { decathlonSearch } = storeConfig

const TreinoEmCasaComponent = () => {
  const [searchResult, setSearchResult] = useState<SearchResult | null>(null)

  useEffect(() => {
    const getShelfData = async () => {
      const isB2B = checkEnviromentIsB2B()

      try {
        const { data } = await axios.get(
          `${decathlonSearch}/product/collection?engine=vtex&sc=${
            isB2B ? B2B.salesChannel : B2C.salesChannel
          }&sort=descsold&term=411`
        )

        setSearchResult(data)
      } catch (error) {
        console.error(error)
      }
    }

    getShelfData()
  }, [])

  return (
    <main className="lp-treino-em-casa">
      <img
        src="https://decathlonpro.vteximg.com.br/arquivos/banner-desktop-treino-em-casa.png"
        alt="Treino em Casa | Decathlon"
      />
      <TrainPartnes />
      <DicasTreino />
      <CompleteTrains />
      <TrainInHouse />
      {searchResult && (
        <ProductSlider
          title="PRODUTOS PARA AJUDAR NO SEU TREINO"
          products={searchResult?.products}
          linkButton="https://www.decathlon.com.br/pesquisa/?q=treino-casa&pageType=hotsite"
          descriptionButton="Ver os produtos"
        />
      )}
    </main>
  )
}

export default TreinoEmCasaComponent
