import { Statsig, useExperiment } from 'statsig-react'
import GiftCardModal from 'src/components/ui/Modal'
import { publish } from 'src/components/restructure/product/productEvents'
import { useShippingContext } from 'src/components/restructure/product/contexts/shipping-context'
import { ProductContext } from 'src/components/restructure/product/contexts'
import { useContext } from 'react'
import { makeAddToCartEvent, sendEvent } from 'src/utils/restructure/analytics'

import { useBuyButton } from './hooks/useBuyButton'

interface BuyButtonProps {
  quantity: number
}

export function BuyButton({ quantity }: BuyButtonProps) {
  const { config } = useExperiment('teste_add_to_cart-comprar_agora')
  const { isSimulatingShipping } = useShippingContext()
  const { product, currentSku, deliveryIsAvailable } =
    useContext(ProductContext)

  const { addToCart, mapperCartItem, openModal, setOpenModal } = useBuyButton()
  const { isAvailable } = currentSku
  const shouldDisable =
    isAvailable === false ||
    isSimulatingShipping ||
    deliveryIsAvailable === false

  const handleBuyButton = () => {
    publish('addToCart')

    if (!deliveryIsAvailable) {
      return
    }

    const addToCartEvent = makeAddToCartEvent([
      { product, productItem: currentSku },
    ])

    sendEvent(addToCartEvent)
    try {
      Statsig.logEvent('add_to_cart')
      // eslint-disable-next-line no-empty
    } catch {}

    const cartItem = mapperCartItem(product, currentSku, quantity)

    addToCart(cartItem)
  }

  const buttonLabel =
    (config?.value?.button_label as string) ?? 'Adicionar ao carrinho'

  return (
    <>
      <button
        data-testid="buy-button"
        disabled={shouldDisable}
        className={`${
          shouldDisable
            ? 'cursor-not-allowed border-restructure-border-disabled bg-restructure-background-disabled text-restructure-disabled hover:border-restructure-border-disabled hover:text-restructure-disabled'
            : ''
        } flex-1 w-full restructure-tablet:w-auto hover:bg-restructure-background-action-1-hover bg-restructure-background-action-1 px-xxs py-sm rounded-round desktop-body-semibold-text2 text-restructure-primary-inverted`}
        onClick={handleBuyButton}
      >
        {buttonLabel}
      </button>

      <GiftCardModal
        message="Não é possível comprar o Cartão Presente junto de outros produtos. Inicie uma nova compra para realizar a ação."
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </>
  )
}
