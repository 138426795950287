import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import Icon from 'src/components/ui/Icon/Icon'
import { formatterPrice } from 'src/utils/formatterPrice'
import { InformationIcon } from 'src/components/Icons/InformationIcon'

import { useFreightSimulation } from './useFreightSimulation'
import type { FreightSimulationData } from './useFreightSimulation'

interface FreightSimulationProps {
  items: Array<{
    id: string
    seller: string
    quantity: number
  }>
  cep: string
  setDeliveryIsAvailable: (arg: boolean) => void
  setIsSimulatingShipping: (arg: boolean) => void
  delievryIsAvailable: boolean | null
}

export function FreightSimulation({
  items,
  cep,
  setDeliveryIsAvailable,
  setIsSimulatingShipping,
  delievryIsAvailable,
}: FreightSimulationProps) {
  const [freightSimulation, setFreightSimulation] =
    useState<FreightSimulationData | null>(null)

  const [loadingFreightSimulation, setLoadingFreightSimulation] =
    useState(false)

  const { getFreightSimulation } = useFreightSimulation()

  useEffect(() => {
    const getData = async () => {
      setDeliveryIsAvailable(false)
      setLoadingFreightSimulation(true)
      setIsSimulatingShipping(true)

      const response = await getFreightSimulation(items, cep)

      const deliveryIsAvailable = response?.packages?.some(
        (item) => item?.deliveryTypes?.length > 0
      )

      setFreightSimulation(response)
      setDeliveryIsAvailable(deliveryIsAvailable ?? false)
      setIsSimulatingShipping(false)
      setLoadingFreightSimulation(false)
    }

    getData()
  }, [
    getFreightSimulation,
    setDeliveryIsAvailable,
    items,
    cep,
    setIsSimulatingShipping,
  ])

  function formatDeliveryTpeName(deliveryType: string) {
    return deliveryType
      .replace('economic', 'Econômica:')
      .replace('pick-up', 'Retire na loja:')
      .replace('fast', 'Rápido:')
  }

  function getDeliveryTypeIcon(deliveryType: string) {
    const iconName = deliveryType
      .replace('economic', 'EconomicFreight')
      .replace('pick-up', 'PickUpFreight')
      .replace('fast', 'FastFreight')

    return <Icon name={iconName} width={20} height={20} />
  }

  return (
    <div data-testid="resume-simulation-shipping">
      {!loadingFreightSimulation && freightSimulation && (
        <ul>
          {freightSimulation.packages.map((packageItem) => (
            <li key={packageItem.seller}>
              <ul className="flex flex-col gap-sm mt-md">
                {packageItem.deliveryTypes.map((deliveryType) => (
                  <li
                    className="flex items-center gap-xxs"
                    key={deliveryType.type}
                  >
                    {getDeliveryTypeIcon(deliveryType.type)}
                    <span className="mobile-body-regular-text3 text-restructure-primary">
                      {formatDeliveryTpeName(deliveryType.type)}
                    </span>
                    <span className="text-restructure-tertiary">
                      {deliveryType.shippingEstimate}
                    </span>

                    {deliveryType.value === 0 ? (
                      <p className="desktop-body-regular-text3 text-restructure-success mr-0 ml-auto">
                        Grátis
                      </p>
                    ) : (
                      <p className="desktop-body-regular-text3 text-restructure-primary mr-0 ml-auto">
                        {formatterPrice(deliveryType.value / 100)}
                      </p>
                    )}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      )}
      {loadingFreightSimulation && (
        <div className="flex flex-col gap-sm mt-md" data-testid="skeleton">
          <Skeleton className="w-full" height={24} />
          <Skeleton className="w-full" height={24} />
        </div>
      )}

      {!loadingFreightSimulation && !delievryIsAvailable && (
        <div className="flex gap-1">
          <div className="w-[16px]">
            <InformationIcon color="#CC212A" height={16} width={16} />
          </div>
          <p className="font-inter text-xs text-restructure-error font-normal">
            Este produto está indisponível para este CEP. Altere ou insira outro
            CEP para verificar a disponibilidade.
          </p>
        </div>
      )}
    </div>
  )
}
