import { useContext, useEffect, useMemo, useState } from 'react'
import { useCepStorage } from 'src/components/common/CepComponent/hooks/CepInformationContext'
import { FreightSimulation } from 'src/components/restructure/common/FreightSimulation'
import { FreightModal } from 'src/components/restructure/common/FreightSimulation/FreightModal'
import { ProductContext } from 'src/components/restructure/product/contexts/product-context'
import { subscribe } from 'src/components/restructure/product/productEvents'

import { useShippingContext } from '../../contexts/shipping-context'

export function Shipping() {
  const { addressStorage } = useCepStorage()
  const {
    currentSku,
    specificationMustBeSelected,
    deliveryIsAvailable,
    setDeliveryIsAvailable,
  } = useContext(ProductContext)

  const { setIsSimulatingShipping } = useShippingContext()

  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    if (addressStorage) {
      subscribe('addToCart', () => setOpenModal(false))
    }

    if (!addressStorage) {
      subscribe('addToCart', () => setOpenModal(true))
    }
  }, [addressStorage])

  const readyToGetFreightSimulation =
    specificationMustBeSelected === null && addressStorage

  const zipCode = useMemo(
    () => addressStorage?.postalCode ?? '',
    [addressStorage]
  )

  const items = useMemo(
    () => [
      {
        id: currentSku.itemId,
        seller: currentSku.sellerDefault.sellerId,
        quantity: 1,
      },
    ],
    [currentSku]
  )

  return (
    <section id="shipping">
      <div className="flex items-center flex-wrap gap-y-md justify-between p-md rounded-sm bg-restructure-background-secondary">
        {addressStorage && (
          <p className="desktop-caption-regular text-restructure-secondary">
            <span>
              {addressStorage.city} / {addressStorage.state}
            </span>
            <span className="block">{addressStorage.postalCode}</span>
          </p>
        )}

        {!addressStorage && (
          <p className="desktop-caption-regular text-restructure-secondary w-full restructure-tablet:w-auto">
            Consulte os prazos de entrega e retirada
          </p>
        )}

        <button
          className="desktop-caption-semibold text-restructure-action"
          data-testid="openModalSimulation"
          onClick={() => setOpenModal(true)}
        >
          {addressStorage ? 'Alterar CEP' : 'Adicionar CEP'}
        </button>
      </div>

      {readyToGetFreightSimulation && (
        <FreightSimulation
          items={items}
          cep={zipCode}
          delievryIsAvailable={deliveryIsAvailable}
          setIsSimulatingShipping={setIsSimulatingShipping}
          setDeliveryIsAvailable={setDeliveryIsAvailable}
        />
      )}

      <FreightModal isOpen={openModal} onClose={() => setOpenModal(false)} />
    </section>
  )
}
