import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import type { ProductItem } from 'src/components/restructure/product/types/productItem'
import type {
  Product,
  SkuSpecification,
} from 'src/components/restructure/product/types/product'

import { getInitialItem } from '../../utils/get-initial-sku'

type ProductProviderProps = {
  children: React.ReactNode
  product: Product
  slug: string
}

type ProductContextProps = {
  product: Product
  currentSku: ProductItem
  initialSku: ProductItem
  specificationMustBeSelected: SkuSpecification | null
  setSpecificationMustBeSelected: Dispatch<
    React.SetStateAction<SkuSpecification | null>
  >
  setCurrentSku: Dispatch<SetStateAction<ProductItem>>
  deliveryIsAvailable: boolean | null
  setDeliveryIsAvailable: Dispatch<SetStateAction<boolean | null>>
}

export const ProductContext = createContext<ProductContextProps>(
  {} as ProductContextProps
)

export const ProductProvider = ({
  children,
  product,
  slug,
}: ProductProviderProps) => {
  const initialSku = getInitialItem(product.items, slug)
  const [currentSku, setCurrentSku] = useState(initialSku)
  const [deliveryIsAvailable, setDeliveryIsAvailable] = useState<
    boolean | null
  >(null)

  const [specificationMustBeSelected, setSpecificationMustBeSelected] =
    useState<SkuSpecification | null>(null)

  useEffect(() => {
    // to change current sku when product is changed
    setCurrentSku(getInitialItem(product.items, slug))
  }, [product, slug])

  const productContextValue = useMemo(
    () => ({
      product,
      initialSku,
      currentSku,
      specificationMustBeSelected,
      setSpecificationMustBeSelected,
      setCurrentSku,
      deliveryIsAvailable,
      setDeliveryIsAvailable,
    }),
    [
      product,
      initialSku,
      currentSku,
      specificationMustBeSelected,
      setSpecificationMustBeSelected,
      deliveryIsAvailable,
      setDeliveryIsAvailable,
    ]
  )

  return (
    <ProductContext.Provider value={productContextValue}>
      {children}
    </ProductContext.Provider>
  )
}

export const useProductPageContext = () => useContext(ProductContext)
